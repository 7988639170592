import React, { useEffect } from "react";




export default function BirthStory22() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return <div>BirthStory22</div>;
}
