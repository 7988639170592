import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/blog_global.css"

import Public17Pic1 from "../../../image/publications/public-17-hero-brigi-szuletesenek-tortenete.png";
import Public17Pic2 from "../../../image/publications/public-17-brigi-szuletesenek-tortenete.png";

export default function Public17() {
  return (
  <div><section className="section-blog-global">
  <div className="blog-global-container">
      <div className="blog-global-hero">
          <img src={Public17Pic1} alt="Brigi szuletesenek tortenete"/>
      </div>

      <div className="blog-global-face">
          <h2 className="blog-global-title">Brigi születésének története</h2>
          <div className="blog-global-profile-container">
              <div className="blog-global-profile">
                  <div className="blog-global-img-container">
                      
                  </div>
                  <div className="blog-global-text">
                      <h3>Békés Emőke</h3>
                      <p>2009. július 21.</p>
                  </div>
              </div>
              <div className="blog-global-button">
                  <Link to="/publications" className="blog-global-btn">Publikációim</Link>
              </div>
          </div>

          <div className="blog-globale-column">
              <div className="blog-globale-img-container">
                  <img src={Public17Pic2} alt="Brigi szuletesenek tortenete"/>
              </div>
              <div className="blog-globale-text">
                  <p>
                      <b>Drága Petra!</b>
                  </p>
                  <p>
                      Amikor ezt a levelet olvasod, két születésnap is nagyon közel lesz már. Az egyik a Tiéd, a másik a kisfiadé, aki talán – mint a legszebb ajándék – pont ugyanezen a napon fog a világra érkezni.
                  </p>
                  <p>
                      Emlékszel az első levélváltásunkra? Akkor Te írtál nekem. A szakdolgozatodhoz gyűjtöttél anyagot, és én örömmel segítettem ebben. A dolgozat elkészült, és Te hamarosan várandós lettél. J
                  </p>

              </div>
          </div>
          
          
          <p>
              Ekkor kerestél meg újra, hogy legyek a kísérőtök a kisbabátok megszületéséhez vezető úton.
          </p>
          <p>
              Nagyon szerettem veletek beszélgetni. Nyitottak voltatok és érdeklődőek. Sokat kérdeztetek és megfontoltan készültetek a nagy napra. De nem csak ezért volt jó találkozni veletek, hanem azért is, mert élmény volt látni a köztetek lévő mély szeretetet és harmóniát.
          </p>
          <p>
              Brigi születésének történetéből nagyon sokat tanultam. Neked köszönhetem, hogy láthattam, micsoda ereje van annak, ha egy szülő nő hisz önmagában.
          </p>
          <p>
              Sajnos nem volt háborítatlan a vajúdásod. Nagyon komoly negatív üzenetekkel, sok elbizonytalanító mondattal találtad szemben magad, pont akkor, amikor a legnagyobb biztonságra és bíztatásra lett volna szükséged. A szülésznőd nem hitt abban, amit Te tudtál. S bár nem volt egyáltalán ok sem az aggodalomra, sem a sietségre, mégis gyorsítani akarták folyamatot.
          </p>
          <p>
              Időt kértél, és aztán döntést hoztál. Nem engedted, hogy az amúgy szépen haladó vajúdásodba gyógyszerrel beavatkozzanak. Csendesen, de határozottan azt kérted, hogy hagyjanak békén.
          </p>
          <p>
              Megtették.
          </p>
          <p>
              Elmentek. Túl még a kórház kapuján is.
          </p>
          <p>
              Akkor nem így éreztük, de ma már tudjuk, milyen jó volt ez így. Kiengedtük az ablakon a felgyűlt feszültséget, leültél egy labdára, és az ágyra hajtottad a fejed. Mögéd ültem és masszíroztalak. Nem beszéltünk. Figyeltem, ahogy ringatózol a méhösszehúzódás hullámain és éreztem, ahogy hívod a babád.
          </p>
          <p>
              Csodáltalak. Még ma is azt gondolom, bizony nagyon nehéz lehet egy először szülő nőnek bármire is nemet mondani kórházi körülmények között. De Téged mégsem rendítettek meg abban a hitben, hogy Veled is, a babáddal is minden rendben van. Tudtam, hogy olyan felelős döntést hoztál, amelyben mindent gondosan mérlegeltetek a pároddal.
          </p>
          <p>
              Sokszor nem könnyű nekem sem, amikor egy vajúdó nő olyan helyzetbe kerül, ahol döntenie kell arról, hogy a szakemberek felől érkező információk alapján beleegyezzen valami beavatkozásba, vagy pedig az ismeretei és leginkább a belső ösztönei által vezérelve elutasítsa azt.
          </p>
          <p>
              Nem döntök helyette és nem is tehetem. Neked is csak abban tudtam segíteni, hogy minden információ a birtokodban legyen, és igyekeztem, hogy azon az ösvényen, amelyre lépsz, a tőlem telhető legjobb módon kísérhesselek tovább.
          </p>
          <p>
              Nagyon boldog voltam, amikor kislányotok, Brigi, megtréfálva a szkeptikusokat, sok órával azelőtt, mint amikorra jósolták, a természet rendje szerint megérkezett. Gyönyörű volt és makkegészséges! J
          </p>
          <p>
              Ennek immár két és fél éve, mégis jól emlékszem megszületése minden percére.
          </p>
          <p>
              Köszönöm, hogy most újra meghívást kaptam tőletek a kisfiatok érkezéséhez is. Különösen nagy örömmel készülök erre az alkalomra, hiszen ő lesz az első kistesó, akinek már másodszorra segíthetem a szüleit.
          </p>
          <p>
              Szívből kívánom, hogy a babád utazása a benti világból a kintibe most teljes háborítatlanságban, mindenféle zavaró körülmény nélkül, a szívetek szerint történhessen meg!
          </p>
          <p>
              Ezúton kívánok most előre is boldog születésnapokat Nektek!
          </p>
          <p>Emőke</p>

          <p>
              <a href="https://nlc.hu/baba/20090721/brigi_szuletesenek_tortenete_-_a_dula_valaszol/">https://nlc.hu/baba/20090721/brigi_szuletesenek_tortenete_-_a_dula_valaszol/</a>
          </p>
      </div>

      <div className="blog-global-tags">
          <p>Címkék:</p>
           <Link to="/olvasnivalok">Olvasnivalók</Link> 
                <Link to="/publications">Publikációim</Link>
      </div>
  </div>
</section></div>
  )
}
