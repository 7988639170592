import React from "react";
import { Link } from "react-router-dom";
import "../../../CSS/blog_global.css"

import Public20Pic1 from "../../../image/publications/public-20-hero-ket-unnep-kozt-egy-harmadik.png";
import Public20Pic2 from "../../../image/publications/public-20-ket-unnep-kozt-egy-harmadik.jpg";

export default function Public20() {
  return (
  <div>
     <section className="section-blog-global">
  <div className="blog-global-container">
      <div className="blog-global-hero">
          <img src={Public20Pic1} alt="ket-unnep-kozt-egy-harmadik"/>
      </div>

      <div className="blog-global-face">
          <h2 className="blog-global-title">Két ünnep közt egy harmadik</h2>
          <div className="blog-global-profile-container">
              <div className="blog-global-profile">
                  <div className="blog-global-img-container">
                      
                  </div>
                  <div className="blog-global-text">
                      <h3>Békés Emőke</h3>
                      <p>2009. június 09.</p>
                  </div>
              </div>
              <div className="blog-global-button">
                  <Link to="/publications" className="blog-global-btn">Publikációim</Link>
              </div>
          </div>

          <div className="blog-globale-column">
              <div className="blog-globale-img-container">
                  <img src={Public20Pic2} alt="ket-unnep-kozt-egy-harmadik"/>
              </div>
              <div className="blog-globale-text">
                  <p>
                      <b>Már csütörtökön este éreztem, hogy valami mintha finoman, de mégis egyre inkább szorongatná a szívemet.</b>
                  </p>
                  <p>
                      Aztán amikor másnap reggel, a ballagás napján bekísértem kislányomat, Esztert az oviba, ki is gördült könnyek formájában az az ünnepi hangulattal és büszkeséggel párosuló szomorúság, ami annak volt köszönhető, hogy a legkisebb gyermekünk is elbúcsúzik az óvodától.<br/>
                      Amíg a boltig elértem, végigpergettem magamban Eszterke kicsi életének fontosabb eseményeit és újra csak hitetlenkedve merengtem azon, hogy biztos-e, hogy több mint hat éve volt, amikor csendesen megérkezett közénk és a karjaimba vehettem.
                  </p>
                  

              </div>
          </div>
          <p>
              A melankolikus hangulatból a praktikus teendőim húztak vissza a jelenbe, hiszen alapos körültekintéssel kellett bevásárolnom, méghozzá nem csak az aznapi vacsorára, ahová mind a négy nagyszülőt is meghívtuk, hanem a vasárnap délutáni kerti gyerekzsúrra is. Eszternek pont vasárnapra esett a névnapja, így ekkorra szervezte élete első buliját.<br/>
              A nagy készülődésben egyre növekvő izgalommal vártam Katiék hívását, akik azzal a kéréssel tiszteltek meg, hogy legyek a kísérőjük második gyermekük megszületésénél.<br/>
              Bizony nehéz helyzet egy dúla életében az, amikor egy családi ünnep és egy szülés várható időpontja ennyire közel esik egymáshoz. Katinak már korábban felajánlottam azt, hogy ha a két esemény egy időben történne, akkor egy általa is ismert és elfogadott másik dúla helyettesíthet engem, de ezzel a lehetőséggel nem kívánt élni, ugyanakkor mély megértéssel fogadta, hogy Eszternek is, nekem is nagyon fontos, hogy láthassam, amint elbúcsúzik az óvodától. Kedvesen nyugtatgatott, hogy ne izguljak, nem fog a ballagás óráiban szülni, bár ekkor már túl volt a kiírás napján.
          </p>
          <p>
              Azért délelőtt felhívtam és érdeklődtem a hogyléte felől. Minden rendben volt, így átadhattam magam az ünnep örömének. Aranyosak és szépek voltak a nagycsoportos gyerekek, de azért a verset természetesen a mi kislányunk mondta a legszebben. 
          </p>
          <p>
              Este a vacsora után még sokáig beszélgetett a családunk az asztalnál.
          </p>
          <p>
              Szombaton reggel, amikor megszólalt a telefonom, vidáman ugrottam ki az ágyból, mert tudtam, hogy Kati nevét fogom látni a kijelzőn.<br/>
              Arról számolt be, hogy rendszeres, de még nem túl sűrűn jelentkező méhösszehúzódásai vannak. Nem akart korán a kórházba menni, azt mondta, nagyon jó még otthon lennie, így abban maradtunk, hogy telefonon tartjuk a kapcsolatot, hív, ha bármi változást érez, vagy ha szüksége lenne rám.<br/>
              Gyorsan nekiálltam a másnapi névnapi zsúr előkészületeinek. Süteményeket sütöttem, házi bodzalevet készítettem és összeállítottam a vetélkedő feladatait, az azokhoz szükséges eszközöket és a tombolanyereményeket.<br/>
              Közben vártam a híreket. Kati néha felhívott és elmondta, hogy a kontrakciók nem álltak le, de nem is sűrűsödtek jelentősen. Úgy gondoltam, hogy talán ha majd lefekteti este a kisfiát, akkor fog megindulni a kisbaba nagyobb lendülettel.
          </p>
          <p>
              És az éj leszállt.
          </p>
          <p>
              A férjem másnap korán reggel indult külföldre, így elbúcsúztunk egymástól. A nagylányok izgatottan kérdezték, hogy mi lesz, ha éjjel megyek el a kórházba és nem érek haza a buli kezdetére. Nagyon segítőkészek a gyerekeim, de azért azt nem tartottuk túl szerencsésnek, hogy 10 -12 kicsi felnőtt felügyelete nélkül töltse a délutánt. Megbeszéltük, hogy megpróbálom majd rövid sms-ben értesíteni őket a kórházból, hogy halasszuk-e el a gyerekzsúrt vagy esetleg hazaérek.<br/>
              Lefeküdtem, de elaludni nem tudtam. Ilyenkor már olyan készültségi állapotban vagyok, ami legfeljebb csak felszínes szunyókálást enged. A gondolataim a párnál és a születendő babánál járnak.<br/>
              A sötét szobában pihenve most Kati történetén gondolkodtam. Az első szülésén, ami császármetszéssel végződött, és a mostani várandósságának eseményein. A beszélgetéseinken, amelyekre elkísérte a férje, Ádám is, az orvosa válaszain, amikor a szülési terv egyeztetése zajlott, és azon az anyák körében nem túl népszerű szabályon, hogy ebben a kórházban nem lehet szülésznőt választani.<br/>
              Fohászkodtam magamban azért, hogy a bizonytalan körülmények jól álljanak össze, hogy a jelenlévők mindannyian bízzanak abban, amiben a szülők és én, hogy a kis Boldizsár hüvelyi úton is meg tud születni és azért, hogy én is a lehető legjobb módon tudjam majd a családot segíteni.
          </p>
          <p>
              Fél kettőkor szólalt meg újra a telefonom. Még Katival beszéltem meg, hogy lassan indulhatunk, mert a kontrakciók már jó erősek és sűrűek, de a továbbiakat már Ádámmal egyeztettük. Annak mindig örülök, amikor az anyának már nincs kedve velem beszélgetni, hiszen ez azt jelenti, hogy aktívan vajúdik.<br/>
              Gyorsan autóba ültem. A város meglepően nyüzsgő volt ezen a késői-korai órán. Enyhe, nyárias szombat éjszaka volt, áramlottak ki a Margitszigetről a fiatalok, a Körúton vidám társaságok várták az éjjeli buszjáratokat, a taxisok pedig utasokat remélve tétován rótták a köröket a járdák mentén. Valamelyik rádiócsatornán a magyar dalok éjszakája szólt. Dúdolgatva vezettem a kórházig.<br/>
              Talán fél négy is elmúlt már, mire berendezkedhettünk a szülőszobában, és a felvételi procedúra során lelassult kontrakciók újra rendszeressé váltak.
          </p>
          <p>
              Különös időtlenségbe burkolt a vajúdás hangulata. A szülésznő kérte, hogy figyeljem, hány percenként jönnek az összehúzódások, igyekeztem ennek a kérésnek eleget tenni. Számomra a szülőszobai órákon általában csak percmutató van, az órákat jelző kismutató a vajúdás idején a semmibe foszlik, csak a kisbaba kibújásának pillanatában válik újra láthatóvá. Az ablaktalan, zsilipes ajtóval záródó szülőszobában egyébként is teljesen eltűnt a külvilág.
          </p>
          <p>
              Nem tudom, hányszor ment körbe a nagymutató. Nem ez volt a fontos, hanem az, hogy a méhszáj tágulni kezdjen. A császármetszés utáni hüvelyi szülésnek egyik fontos feltétele ugyanis, hogy a tágulásnak mindenféle külső beavatkozás és gyorsítás nélkül kell megtörténnie. A baba fekvése eleinte nem segítette annyira ezt a folyamatot, meg kellett hát találni azt a vajúdási pozíciót, amelyik a babának is, az anyának is kedvező és a méhszájra is jól hat.
          </p>
          <p>
              Nagy öröm volt, amikor az egyik vizsgálat után kiderült, hogy jó úton haladunk!
          </p>
          <p>
              A hír hallatán felkerekedtünk, és kimentünk a folyosóra sétálni. Csodálkozva láttuk, hogy odakinn már süt a nap, reggel van.
          </p>
          <p>
              Kati és Ádám az ablakmélyedésbe húzódott. Összebújtak. Kicsit távolabb az előtérben lévő székek egyikére ültem és onnan figyeltem őket. Kati sírni kezdett, egyre jobban csorogtak az arcán az öröm, a fájdalom, a fáradtság és a remény könnyei. Ádám ölelte és szerette őt. Nagyon és nagyon jól. Csendesen, megértően, nem csitítva a Katit egyre jobban rázó zokogást.<br/>
              Sokat meséltem nekik a sírás segítő erejéről, így nem érte egyiküket sem váratlanul az érzelmek áradása, tudták, hogy ez mennyit segít az elengedésben. Örültem, hogy a szülésznő is megértette ezt, mielőtt odament volna hozzájuk vigaszt nyújtani.<br/>
              Valamikor később, a délelőtt folyamán a telefonom sms-eket jelző halk berregése arra hívta fel a figyelmemet, hogy a gyerekek kíváncsian várják otthon, hogy mi a helyzet.<br/>
              
          </p>
          <p>
              Egy alkalmas pillanatban jeleztem a szülőknek, hogy néhány percre ki szeretnék menni. A mosdóból hazatelefonálva gyors kupaktanácsot tartottunk, bár a vajúdásnak abban a szakaszában még megjósolhatatlan volt, hogy mikor érkezik meg a kisbaba.<br/>
              Valamiért mégis úgy éreztem, hogy el lehet készíteni a szendvicseket és a lufikat is fel lehet fújni, lesz itt még ünneplés ma.<br/>
              A telefonomat letéve vettem észre egy másik sms-t is. Egy velem szült anya – aki értesült arról, hogy éppen a kórházban vagyok – felajánlotta, hogy a férjével és a kislányukkal kijönnek hozzánk, mindenben segítenek, és ha nem érnék haza, a bulit is levezénylik. Nagy melegség öntötte el a szívemet.
          </p>
          <p>
              Teljes figyelemmel tudtam hát e néhány perc elteltével újra a párral lenni, és ez mély megnyugvással töltött el.
          </p>
          <p>
              A folyamat pedig gyönyörűen haladt. Kati teste Ádám karjaiban ringott a méhösszehúzódások hullámainak ritmusában, és ebben a szerelmes összefonódásban hajóztak el egészen addig, míg érkező babájuk feje előtt ki nem tárult a kapu. Megható volt látni, hogy Kati úgy élte meg ezt a kinyílást, mint valami varázslatot. Hihetetlen volt számára, hogy kisfia érkezése most az ő munkájának és a teste bölcsességének eredménye.
          </p>
          <p>
              A születés pillanataiban három örökre megmaradó képrészlet rögzült az emlékeimben róluk: a baba kigördülő fejecskéje, Kati szorító keze és Ádám rengeteg érzelemről árulkodó szemei.<br/>
              És ekkor a nagymutató is újra láthatóvá vált. Az óra 11.34 -et mutatott, mikor Kati magához ölelte a kisfiát.
          </p>
          <p>
              Boldog voltam, mert tudtam, hogy ennek a szülésnek az emléke túlmutat a jelen pillanatán és katarzisán. Gyógyítja az előző emlékét, és még sok minden másra is hatni fog.<br/>
              És hálás, hogy a vajúdás végéig mindkét műszakban dolgozó szülésznő és Kati orvosa is nagyon elfogadó volt a párral és velem, és ami legalább ilyen fontos, hittek abban, hogy nem lehetetlen császármetszés után hüvelyi úton szülni.<br/>
              Boldizsár az apukájával ismerkedett csendesen míg Katit ellátták, aztán lassan minden és mindenki elcsitult a szülőszobában.
          </p>
          <p>
              A kisbaba boldogan és mohón szopizott édesanyja mellén. Csendben összepakoltam, hogy végre hármasban lehessenek. Én nekik örültem, ők pedig tiszta szívből tudtak annak is, hogy időben hazaérhetek.
          </p>
          <p>
              Otthon feldíszített kert, terített asztal és meleg ebéd várt. Ahogy beléptem az ajtón, felkaptam a kis névnapos Eszterkémet és jól megpuszilgattam. Akkor éreztem át igazán, hogy neki milyen nehéz lett volna, ha nem lehetek vele az ünnepén.<br/>
              A nagylányaim mindent előkészítettek, így még egy zuhanyozásra is volt időm, amíg az első vendégek megérkeztek. A buli felhőtlen boldogságban zajlott, amit még az is tetézett, hogy eljött az a család is, akiknek másfél évvel ezelőtt segítettem gyermekük születésénél.
          </p>
          <p>
              Gyönyörű és mozgalmas hétvége volt.
          </p>
          <p>
              Mint a kirakós játékban a puzzle darabkái, olyan tökéletesen illeszkedett be a két családi ünnep közé a harmadik, Boldizsár érkezése.
          </p>

          <p>
              <a href="https://nlc.hu/baba/20090609/ket_unnep_kozt_egy_harmadik/">https://nlc.hu/baba/20090609/ket_unnep_kozt_egy_harmadik/</a>
          </p>
      </div>

      <div className="blog-global-tags">
            <p>Címkék:</p><Link to="/olvasnivalok">Olvasnivalók</Link> 
                <Link to="/publications">Publikációim</Link>
      </div>
  </div>
</section>
      </div>
      )
}
